import PropTypes from 'prop-types';
import Button from '../Button';
import classNames from 'classnames';
import YouTubeVideo from '../YouTubeVideo';
import styles from './YouTubeVideoList.module.scss';

const YouTubeVideoList = ({ title, preamble, link, videos, showTitles }) => {
    let counter = 0;
    const hasLink = link && link.href;
    const isExternalLink = hasLink && /^(?:https?:\/\/|\/\/)/i.test(link.href);

    return (
        <div className={styles['YouTubeVideoList']}>
            <div className={styles['YouTubeVideoList__Container']}>
                <div className={styles['YouTubeVideoList__Row']}>
                    <div className={styles['YouTubeVideoList__Content']}>
                        {title && (
                            <h2 className={styles['YouTubeVideoList__Title']}>{title}</h2>
                        )}
                        {preamble && (
                            <p className={styles['YouTubeVideoList__Preamble']}>{preamble}</p>
                        )}
                    </div>
                    {hasLink && (
                        <div className={classNames([styles['YouTubeVideoList__Button'], styles['YouTubeVideoList__Button--Desktop']])}>
                            <Button
                                link={link}
                                type={'primary'}
                                icon={isExternalLink ? 'external' : 'arrow'}
                            />
                        </div>
                    )}
                </div>

                {videos && (
                    <div className={styles['YouTubeVideoList__List']}>
                        {videos.map((video, i) => {
                            counter++;
                            return (
                                <div
                                    key={i}
                                    className={classNames(
                                        styles['YouTubeVideoList__Item'],
                                        styles[`YouTubeVideoList__Item--${counter}`]
                                    )}>
                                    <YouTubeVideo
                                        index={counter}
                                        videoId={video.id}
                                        title={showTitles ? video.title : undefined}
                                    />
                                </div>
                            );
                        })}
                    </div>
                )}
                {hasLink && (
                    <div className={classNames([styles['YouTubeVideoList__Button'], styles['YouTubeVideoList__Button--Mobile']])}>
                        <Button
                            link={link}
                            type={'primary'}
                            icon={isExternalLink ? 'external' : 'arrow'}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

YouTubeVideoList.propTypes = {
    title: PropTypes.string,
    preamble: PropTypes.string,
    link: PropTypes.object,
    videos: PropTypes.array,
    showTitles: PropTypes.bool
};

YouTubeVideoList.defaultProps = {
    showTitles: false,
};

export default YouTubeVideoList;
