import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from '../Button/Button';
import React, { useEffect, useState } from 'react';
import styles from './YouTubeVideo.module.scss';

const YouTubeVideo = ({ videoId, title, isCover }) => {
    const [isStarted, setIsStarted] = useState(false);
    const [hasTargetingCookie, setTargetingCookie] = useState(false);
    const [mounted, setMounted] = useState(false);

    const ratioClasses = classNames(styles['YouTubeVideo__Ratio'], {
        [styles['YouTubeVideo__Ratio--Cover']]: isCover,
    });

    const checkCookieAcceptance = () => {
        const hasCookie = CookieScript.instance.currentState()?.categories?.includes('targeting');
        hasCookie ? setTargetingCookie(true) : setTargetingCookie(false);
        setMounted(true);
    }

    useEffect(() => {
        if (window.cookieScriptIsLoaded) {
            checkCookieAcceptance();
        }
        else {
            window.addEventListener('CookieScriptLoaded', () => {
                checkCookieAcceptance();
            });
        }

        window.addEventListener('CookieScriptAccept', (e) => {
            if (!e.detail.categories.includes('targeting')) {
                setTargetingCookie(false);
            } else {
                setTargetingCookie(true);
            }
        });

        window.addEventListener('CookieScriptAcceptAll', () => {
            setTargetingCookie(true);
        });

        window.addEventListener('CookieScriptReject', () => {
            setTargetingCookie(false);
            setIsStarted(false);
        });
    }, []);

    const handleAcceptTargetingCookiesOnClick = () => {
        const categories = CookieScript.instance.currentState()?.categories;
        categories.push('targeting');
        CookieScript.instance.acceptAction(categories);
        setIsStarted(true);
    };

    return (
        <div className={styles['YouTubeVideo']}>
            {mounted && (
                <div className={ratioClasses}>
                    {isStarted ? (
                        <div className={styles['YouTubeVideo__Wrap']}>
                            <iframe
                                className={styles['YouTubeVideo__Media']}
                                src={`https://www.youtube.com/embed/${videoId}?autoplay=1&rel=0`}
                                allowFullScreen
                                allow="autoplay"
                            />
                        </div>
                    ) : !hasTargetingCookie ? <AcceptTargetingCookiesContent onButtonClick={() => handleAcceptTargetingCookiesOnClick()} /> : (
                        <div className={classNames(styles['YouTubeVideo__Wrap'], {
                            [styles['YouTubeVideo__Wrap--Gradient']]: title,
                        })}>
                            <img
                                alt="Video preview"
                                src={`https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`}
                                className={styles['YouTubeVideo__Media']}
                            />
                            <button
                                className={styles['YouTubeVideo__Button']}
                                onClick={() => setIsStarted(true)}>
                                <span className="sr-only">Play video</span>
                            </button>
                            {title && (
                                <h3 className={styles['YouTubeVideo__Title']}>{title}</h3>
                            )}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

const AcceptTargetingCookiesContent = ({ onButtonClick }) => (
    <div className={classNames(styles['YouTubeVideo__Wrap'], styles['YouTubeVideo__Wrap--Target'])}>
        <h3 className={styles['YouTubeVideo__TargetTitle']}>
            To watch this video, you must accept targeting cookies
        </h3>
        <div className={styles['YouTubeVideo__TargetButtonWrap']}>
            <Button
                modifiers={["Small"]}
                text={"Accept targeting cookies"}
                onClick={onButtonClick}
            />
        </div>
    </div>
)

YouTubeVideo.propTypes = {
    videoId: PropTypes.string,
    isCover: PropTypes.bool,
};

YouTubeVideo.defaultProps = {
    videoId: '',
    isCover: false,
};

export default YouTubeVideo;
